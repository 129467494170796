import React from 'react'
import { HStack, Text } from 'native-base'
import Animated from 'react-native-reanimated'
import HeaderItem from './header-item/header-item'
import { TouchableOpacity } from 'react-native'
import {SharedValue} from'react-native-reanimated'

type Props = {
    activeDotIndex: SharedValue<number>,
    colorMode: "light" | "dark" | null | undefined,
    titles: string,
    PAGE_WIDTH: number | undefined,
    index: number
}

export default function Header({ titles, activeDotIndex, colorMode, PAGE_WIDTH, index }: Props) {

    return (
        <HStack w="full">
                <HeaderItem  PAGE_WIDTH={PAGE_WIDTH} title={titles} colorMode={colorMode} key={index} index={index} activeDotIndex={activeDotIndex} />
        </HStack>
    )
}