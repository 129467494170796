 // import type { StoreApi, UseBoundStore } from 'zustand';
// import { MMKV } from 'react-native-mmkv';


// export const storage = new MMKV();

// export function getItem<T>(key: string): T {
//   const value = storage.getString(key);
//   return value ? JSON.parse(value) || null : null;
// }

// export async function setItem<T>(key: string, value: T) {
//   storage.set(key, JSON.stringify(value));
// }

// export async function removeItem(key: string) {
//   storage.delete(key);
// }

// type WithSelectors<S> = S extends { getState: () => infer T }
//   ? S & { use: { [K in keyof T]: () => T[K] } }
//   : never;

// export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
//   _store: S
// ) => {
//   let store = _store as WithSelectors<typeof _store>;
//   store.use = {};
//   for (let k of Object.keys(store.getState())) {
//     (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
//   }

//   return store;
// };

import AsyncStorage from '@react-native-async-storage/async-storage';
import type { StoreApi, UseBoundStore } from 'zustand';

// Interfaccia storage per interagire con AsyncStorage
export const storage = {
  async getItem<T>(key: string): Promise<T | null> {
    try {
      const value = await AsyncStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error(`Error retrieving item with key "${key}":`, error);
      return null;
    }
  },
  async setItem<T>(key: string, value: T): Promise<void> {
    try {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem(key, jsonValue);
    } catch (error) {
      console.error(`Error setting item with key "${key}":`, error);
    }
  },
  async removeItem(key: string): Promise<void> {
    try {
      await AsyncStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing item with key "${key}":`, error);
    }
  },
};

// Aggiunge selettori al negozio Zustand
type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S
) => {
  let store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (let k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};


