import React, { useState } from 'react'
import { PreviewBoxMenu, PreviewBoxModal } from '../../ui/components/preview-box/preview-box'
import { ServizioResult, getServizi } from '../../api/servizi';
import Servizi from './Servizi';
import Statistiche from './Statistiche';
import Chiamate from './Chiamate';
import Panoramica from './Panoramica';
import Consumi from './Consumi';
import { useServizio } from '../../core/store/servizio-store';
import Rubrica from './Rubrica';
import { ChiamateModal } from './Chiamate/index-modal';
import { RubricaModal } from './Rubrica/index-modal';
import StatisticheModal from './Statistiche/index-modal';
import { Linking, Platform, Pressable } from 'react-native';
import ServizioModal from './Servizi/index-modal';
import PanoramicaModal from './Panoramica/Modal/index-modal';
import { ScrollView, Text } from 'react-native';
import { storage } from '../../core/utils';
import { checkIsJson } from '../../utils/utils';

export default function Dashboard({ navigation }: any) {
  const servizioSelezionato = useServizio(state => state.servizio);
  const setServizioSelezionato = useServizio(state => state.setServizio);

  const { data: listaServizi, isLoading, isError } = getServizi({
    onSuccess: (data) => {
      // Carica il servizio dal localStorage al momento del successo del caricamento dei dati
      storage.getItem("servizio").then((servizioStorage) => {
        if (!servizioStorage && data && data.length > 0) {
          setServizioSelezionato(data[0]);
        } else if (checkIsJson(servizioStorage)) {
          setServizioSelezionato(JSON.parse(servizioStorage));
        }
      });
    }
  });

  const [activeScroll, setEnableScroll] = useState(true);

  const disableScroll = React.useCallback(() => {
    setEnableScroll(false);
  }, []);

  const enableScroll = React.useCallback(() => {
    setEnableScroll(true);
  }, []);
  return (
    <ScrollView contentContainerStyle={{ padding: 12, gap: 12 }} scrollEnabled={activeScroll} alwaysBounceVertical>
      {/* Lista Servizi */}
      {Platform.OS === "web" ? (
        <PreviewBoxMenu
          servizioSelezionato={servizioSelezionato}
          menuItems={listaServizi}
          onMenuItemPress={(servizio: ServizioResult) => setServizioSelezionato(servizio)}
          icon="layers"
          title="Servizi"
          color="info.400"
        >
          <Servizi servizioSelezionato={servizioSelezionato} listaServizi={listaServizi} />
        </PreviewBoxMenu>
      ) : (
        <PreviewBoxModal
          modalContent={listaServizi && listaServizi.length > 1 ? (
            <ServizioModal
              servizi={listaServizi}
              onPress={(servizio: ServizioResult) => setServizioSelezionato(servizio)}
              navigation={navigation}
            />
          ) : null}
          navigation={navigation}
          icon="layers"
          title="Servizi"
          color="info.400"
        >
          <Servizi servizioSelezionato={servizioSelezionato} listaServizi={listaServizi} />
        </PreviewBoxModal>
      )}

      {/* Consumi */}
      <PreviewBoxModal modalContent={null} navigation={navigation} icon="pie-chart" title="Consumi" color="#C492B1">
        <Consumi servizioSelezionato={servizioSelezionato} />
      </PreviewBoxModal>

      {/* Panoramica */}
      <PreviewBoxModal
        modalContent={<PanoramicaModal servizioSelezionato={servizioSelezionato} />}
        navigation={navigation}
        icon="cube"
        title="Panoramica"
        color="#E6736B"
      >
        <Panoramica
          hideRangeDate={false}
          servizioSelezionato={servizioSelezionato}
          enableScroll={enableScroll}
          disableScroll={disableScroll}
        />
      </PreviewBoxModal>

      {/* Statistiche */}
      <PreviewBoxModal
        modalContent={<StatisticheModal servizioSelezionato={servizioSelezionato} />}
        navigation={navigation}
        icon="analytics"
        title="Statistiche"
        color="secondary.100"
      >
        <Statistiche servizioSelezionato={servizioSelezionato} />
      </PreviewBoxModal>

      {/* Chiamate */}
      <PreviewBoxModal
        modalContent={<ChiamateModal servizioSelezionato={servizioSelezionato} />}
        navigation={navigation}
        icon="call"
        title="Chiamate"
        color="#60D39C"
      >
        <Chiamate servizioSelezionato={servizioSelezionato} />
      </PreviewBoxModal>

      {/* Rubrica */}
      {servizioSelezionato?.nominativo?.length > 0 && (
        <PreviewBoxModal
          modalContent={<RubricaModal segreteriaSelezionata={servizioSelezionato} navigation={navigation} />}
          navigation={navigation}
          icon="book"
          title="Rubrica"
          color="primary.200"
        >
          <Rubrica
            isLoading={isLoading}
            isError={isError}
            segreteriaSelezionata={servizioSelezionato}
          />
        </PreviewBoxModal>
      )}
    </ScrollView>
  );
}
