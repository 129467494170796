import React from 'react'
import { VictoryAxis, VictoryChart, VictoryArea, VictoryTooltip, VictoryVoronoiContainer, VictoryLabel } from 'victory-native';
import { colors } from '../../../ui/theme/generic-theme/colors';
import { Circle, G, Line, LinearGradient, Stop, Svg } from 'react-native-svg';
import { VStack, Text, ColorMode } from 'native-base';
import { Platform } from 'react-native';

type Props = {
  colorMode: "light" | "dark" | undefined | null;
  dataSet: any;
  totale_chiamate: number;
  width_screen: number;
  enableScroll: (() => void) | undefined;
  disableScroll: (() => void) | undefined;
}


export default function LinearChart({ colorMode, dataSet, totale_chiamate, width_screen, enableScroll, disableScroll }: Props) {

  const [hoverData, setHoverData] = React.useState(null);


  //Create a custom label component that avoid rerendering of the chart
  const Label = ({ x, y, datum }: any) => {
    return (
      <G>
        <G stroke={colors.gray['200']} strokeWidth={4} >
          <Circle cx={x} cy={y} r={15} fill={colorMode === "dark" ? colors.white : colors.primary[400]} />
        </G>
        {/* Create a vertical line  */}
        <Line x1={x} x2={x} y1={20} y2={190} stroke={colorMode === "dark" ? colors.white : colors.primary[400]} strokeWidth={2} />
      </G>
    )
  };

  const onTouchEnd = () => {
    if (enableScroll) {
      enableScroll();
    }
    setHoverData(null);
  };


  const AreaChartShadowGradient = ({ x, y, datum }: any) => (
      <G>
        <LinearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <Stop offset="0%" stopColor={colorMode === "dark" ? colors.white : colors.primary[400]} stopOpacity={0.1} />
          <Stop offset="100%" stopColor={colorMode === "dark" ? colors.white : colors.primary[400]} stopOpacity={0} />
        </LinearGradient>
      </G>
    );


  return (
    <VStack w="full" alignItems={"center"}>
      <VStack alignItems="center" justifyContent={"center"} >
        <Text color="dark" _dark={{ color: "white" }} fontWeight={"300"} fontSize={"2xl"}>{!hoverData ? "Totale Chiamate" : "Chiamate " + (hoverData as any)?.periodo}</Text>
        <Text textAlign={"center"} color="dark" _dark={{ color: "white" }} lineHeight={"xs"} fontWeight={"700"} fontSize={"5xl"}>{!hoverData ? totale_chiamate : (hoverData as any)?.numero_chiamate}</Text>
      </VStack>
      <VictoryChart
        height={220}
        domainPadding={{ x: 5, y: 20 }}
        singleQuadrantDomainPadding={false}
        domain={{ x: [1, dataSet.length] }}
        padding={{ left: 60, top: 10, right: 50, bottom: 10 }}
        containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={({ datum }) => ` `}
          labelComponent={
            <VictoryTooltip flyoutComponent={<Label />} />
          }
          onActivated={(points, props) => setHoverData(points[0])}
          onTouchStart={disableScroll}
          onTouchEnd={onTouchEnd}
          style={{
            width: width_screen > 768 ? "80%" : "100%",

          }}
        />}
      >
        <AreaChartShadowGradient  />
        <VictoryAxis
          dependentAxis
          tickCount={4}
          tickLabelComponent={<VictoryLabel dx={0} />}
          crossAxis={false}
          style={{
            grid: {
              stroke: '#F2F4F7',
            },
            axis: { stroke: 'none' },
            tickLabels: {
              fontSize: 11,
              fill: '#98A2B3',
            },
          }}
        />
        <VictoryAxis
          fixLabelOverlap
          style={{
            axis: {
              stroke: 'none',
              padding: 10,
            },
            tickLabels: {
              fontSize: 11,
              margin: '20px 0 0 0',
              fill: '#98A2B3',
            },
          }}
        />
        <VictoryArea
          interpolation={"catmullRom"}
          style={{
            data: {
              fill: "url(#gradient)",
              stroke: colorMode === "dark" ? colors.white : colors.primary[400],
              strokeWidth: 3
            },
            labels: {
              display: 'none',
            },
          }}
          padding={{ bottom: 1 }}
          data={dataSet}
          x="periodo"
          animate={ Platform.OS === "web" ? undefined : {
            duration: 500,
            // onLoad: { duration: 500 },
            
          }}
          y="numero_chiamate"
        />

      </VictoryChart>
    </VStack>
  )
}

