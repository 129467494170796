import React from "react";
import {
  FormControl,
  Input,
  Pressable,
  Icon,
  VStack,
  Text,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { Controller } from "react-hook-form";
import { FieldErrors } from "react-hook-form";

export type Props = {
  control: any;
  errors: FieldErrors<{ email: string; password: string; }>;
  error: string;
  setError: (error: string) => void;
  navigation: any;
};

export default function LoginForm({ errors, error, setError, navigation, control }: Props) {
  const [show, setShow] = React.useState(false);




  return (
    <VStack space={3} minW="350" >
      <FormControl colorScheme={"dark"} _dark={{
        colorScheme: "light",
      }}
        isInvalid={'email' in errors || !!error}
      >
        <FormControl.Label _dark={{
          _text: {
            color: "white"
          }
        }} _text={{
          color: "dark",
          fontFamily: "body",
        }} >Username</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              isFocused={true}
              testID="email-input"
              textContentType="username"
              autoCapitalize="none"
              onBlur={onBlur}
              onChangeText={function (value) {
                onChange(value)
                if (error) {
                  setError("")
                }
              }}
              value={value}
              _focus={{
                bg: "white",
                borderColor: "dark",
                borderWidth: "1px",
              }}
              _dark={{
                color: "dark"
              }}
              h={55}
              w="100%"
              _android={{
                w: "94%"
              }}
              bg="white"
              rounded={"lg"}
              placeholder="Inserisci il tuo Username"
            />
          )}
          name="email"
          rules={{ required: true, }}
          defaultValue=""
        />
        <FormControl.ErrorMessage >
          {errors?.email ? "L'indirizzo email è obbligatorio" : error ? error : ""}
        </FormControl.ErrorMessage>
      </FormControl>
      <FormControl isInvalid={'password' in errors || !!error}>
        <FormControl.Label _dark={{
          _text: {
            color: "white"
          }
        }} _text={{
          color: "dark",
          fontFamily: "body",
        }} >Password</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              isFocused={true}
              testID="password-input"
              onBlur={onBlur}
              onChangeText={function (value) {
                onChange(value)
                if (error) {
                  setError("")
                }
              }}
              value={value}
              h={55}
              w="100%"
              _focus={{
                bg: "white",
                borderColor: "dark",
                borderWidth: "1px",
              }}
              _dark={{
                color: "dark"
              }}
              _android={{
                w: "94%"
              }}
              bg="white"
              rounded={"lg"}
              type={show ? "text" : "password"}
              InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                  <Icon
                    as={
                      <Ionicons
                        name={show ? "eye" : "eye-off"}
                      />
                    }
                    size={5}
                    mr="2"
                    color="muted.400"
                  />
                </Pressable>
              }
              placeholder="Inserisci la tua password"
            />
          )}
          name="password"
          rules={{ required: true }}
          defaultValue=""
        />
        <FormControl.ErrorMessage >
          {errors?.password ? "La password è obbligatoria" :
            error ? error : ""}
        </FormControl.ErrorMessage>
        <Pressable
          onPress={() => navigation.navigate("Recupero Password")}
        >
          <Text _dark={{ color: "gray.300" }} color="neutral.800" fontWeight={"500"} fontSize="sm" mt="1" mb="5" >
            Hai dimenticato la password?
          </Text>
        </Pressable>

      </FormControl>
    </VStack>
  );
}
