import React from "react";
import {
  FormControl,
  Input,
  HStack,
  Pressable,
  View,
  Heading,
  Center,
  Text,
  useColorMode,
  Icon
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import authRecuperoPassword from "../../../api/auth/recupero-password/recupero-password";
import { SafeAreaView } from "react-native-safe-area-context";
import { colors } from "../../../ui/theme/generic-theme/colors";
type Props = {
  navigation: any;
};

export default function RecuperoPassword({ navigation }: Props) {

  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const { colorMode } = useColorMode();

  const { mutate: recuperoPassword, isLoading, isError, isSuccess } = authRecuperoPassword({
    onError: (error) => {
      const errorResponse = error?.response?.data;
      const key = Object.keys(errorResponse as object)[0];
      const value = (errorResponse as Record<string, any>)[key][key] ? (errorResponse as Record<string, any>)[key][key] : (errorResponse as Record<string, any>)[key][0];
      setError(value);
    },
    onSuccess: (data) => {
      navigation.navigate("RecuperoPasswordSuccess", {
        colorMode: colorMode
      });
    }
  });

  const handleInvioEmail = () => {
    recuperoPassword({
      variables: {
        email: email
      }
    });

  }


  return (
    <SafeAreaView style={{ width: "100%", height: "100%", backgroundColor: colorMode === "dark" ? colors.dark : "white" }}>
      <View flex={1} bg="white" _dark={{
        bg: "dark"
      }} maxH="98%">
        <Pressable onPress={() => navigation.goBack()}  >
          <HStack mt="5" ml="5" alignItems={"center"} >
            <Icon as={<Ionicons name={"arrow-back"} />} color="primary.400" size="6" _dark={{
              color: "white"
            }} />
            <Text _dark={{
              color: "white"
            }} color="primary.400" fontWeight={"bold"} textAlign={"center"} fontSize={"md"} >Indietro</Text>
          </HStack>
        </Pressable>
        <View flex={1} p="2" mt="2" mx="auto" minW={{
          base: "90%",
          md: "600"
        }} maxW={"800"} paddingLeft={"5%"} maxH={{
          base: "95%",
          md: "90%"
        }} paddingRight={"5%"} >
          <Heading
            lineHeight={"xs"}
            color="dark"
            _dark={{
              color: "white"
            }}
            fontWeight={"800"}
            fontSize={"4xl"}
            mb="2"
            fontFamily={"body"}
            textAlign={"left"}
          >
            Recupero Password
          </Heading>
          <Heading
            color="dark"
            fontWeight={"500"}
            _dark={{
              color: "white"
            }}
            fontSize={"md"}
            lineHeight={"xs"}
            fontFamily={"body"}
            textAlign={"left"}
          >
            Inserire l'indirizzo email associato all'account di Segreterie Remote. Ti verrà inviata una mail con le istruzioni per procedere:
          </Heading>
          <Center w="100%" mt="10">
            <FormControl isInvalid={error ? true : false}>
              <FormControl.Label _dark={{
                _text: {
                  color: "white"
                }
              }} _text={{ color: "muted.700", fontSize: "sm", fontWeight: 600 }}>
                Email
              </FormControl.Label>
              <Input
                isFocused={true}
                w="100%"
                isInvalid={isError || error ? true : false}
                placeholder="Email"
                inputMode="email"
                // variant="underlined"
                rounded={"lg"}
                h="55"
                _light={{
                  placeholderTextColor: "blueGray.400",
                }}
                _dark={{
                  color: "dark",
                  bg: "white"
                }}
                _focus={{
                  bg: "white",
                }}
                onChangeText={(text) => setEmail(text)}
              />
            </FormControl>

          </Center>
          {error && <Text color="red.500" mt="2" textAlign={"left"}>{error}</Text>}
          <Pressable mt="auto" bg="primary.400" minH={"55"} p="2" rounded={"md"} onPress={handleInvioEmail} >
            {({
              isHovered,
              isFocused,
              isPressed
            }) =>

              <Center style={{
                opacity: isHovered || isFocused || isPressed ? 0.8 : 1,
                transform: isHovered || isFocused || isPressed ? [{ scale: 0.98 }] : [{ scale: 1 }]
              }} flex={1}>
                <Text color="white" fontWeight={"bold"} textAlign={"center"} fontSize={"lg"} >Invia Email</Text>
              </Center>
            }
          </Pressable>
        </View>
      </View>
    </SafeAreaView>
  );
}
