// import { useMMKVBoolean } from 'react-native-mmkv';

// const IS_FIRST_TIME = 'IS_FIRST_TIME';
// export const useIsFirstTime = () => {
//   const [isFirstTime, setIsFirstTime] = useMMKVBoolean(IS_FIRST_TIME);
//   if (isFirstTime === undefined) {
//     return [true, setIsFirstTime] as const;
//   }
//   return [isFirstTime, setIsFirstTime] as const;
// };

// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { useEffect, useState } from 'react';

// const IS_FIRST_TIME = 'IS_FIRST_TIME';

// export const useIsFirstTime = () => {
//   const [isFirstTime, setIsFirstTime] = useState<boolean | undefined>(undefined);

//   useEffect(() => {
//     const checkIsFirstTime = async () => {
//       const value = await AsyncStorage.getItem(IS_FIRST_TIME);
//       if (value === null) {
//         // Se il valore non esiste, impostiamo il valore iniziale su `true`
//         setIsFirstTime(true);
//       } else {
//         setIsFirstTime(JSON.parse(value));  // Ripristina il valore booleano
//       }
//     };

//     checkIsFirstTime();
//   }, []);


//   const saveIsFirstTime = async (value: boolean) => {
//     try {
//       await AsyncStorage.setItem(IS_FIRST_TIME, JSON.stringify(value));
//       setIsFirstTime(value); // Aggiorna lo stato locale
//     } catch (error) {
//       console.error('Errore durante il salvataggio di IS_FIRST_TIME:', error);
//     }
//   };

//   if (isFirstTime === undefined) {
//     // Se isFirstTime è undefined (in attesa del caricamento), restituisci un array con il valore di default
//     return [true, saveIsFirstTime] as const;
//   }

//   return [isFirstTime, saveIsFirstTime] as const;
// };


import React, { createContext, useContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

const IS_FIRST_TIME = 'IS_FIRST_TIME';

const FirstTimeContext = createContext<{
  isFirstTime: boolean | undefined;
  setIsFirstTime: (value: boolean) => Promise<void>;
}>({
  isFirstTime: undefined,
  setIsFirstTime: async () => { },
});

export const FirstTimeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isFirstTime, setLocalIsFirstTime] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const loadIsFirstTime = async () => {
      const value = await AsyncStorage.getItem(IS_FIRST_TIME);
      if (value === null) {
        setLocalIsFirstTime(true);
      } else {
        setLocalIsFirstTime(JSON.parse(value));
      }
    };
    loadIsFirstTime();
  }, []);

  const setIsFirstTime = async (value: boolean) => {
    await AsyncStorage.setItem(IS_FIRST_TIME, JSON.stringify(value));
    setLocalIsFirstTime(value);
  };

  return (
    <FirstTimeContext.Provider value={{ isFirstTime, setIsFirstTime }}>
      {children}
    </FirstTimeContext.Provider>
  );
};

export const useFirstTime = () => useContext(FirstTimeContext);
