import { storage } from '../utils';


const TOKEN = 'token';

export type TokenType = {
  access: string;
  refresh: string;
};

export const getToken = async () => storage.getItem<TokenType>(TOKEN);
export const removeToken = () => storage.removeItem(TOKEN);
export const setToken = (value: TokenType) => storage.setItem<TokenType>(TOKEN, value);