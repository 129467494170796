import { create } from "zustand";
import { createSelectors, storage } from "../utils";
import { ServizioResult } from "../../api/servizi";

export interface ServizioStore {
    servizio: ServizioResult | null;
    listaServizi: any;
    setListaServizi: (servizi: any) => void;
    isSiriaCentralino: boolean;
    setServizio: (servizio: ServizioResult) => void;
    getServizioId: () => number;
  }
  
  const useServizioStore = create<ServizioStore>((set) => ({
    servizio: null,
    isSiriaCentralino: false,
    listaServizi: [],
    setListaServizi: (servizi: any) => set({ listaServizi: servizi }),
    setServizio: (servizio: ServizioResult) => {
      set({
        servizio,
        isSiriaCentralino: servizio.tipo_pacchetto?.match(/.*caren_light.*/) ? true : false,
      });
      storage.setItem("servizio", JSON.stringify(servizio)); // Memorizza come stringa
    },
    getServizioId: () => useServizioStore.getState().servizio?.id ?? 0,
  }));


export const useServizio: ReturnType<typeof createSelectors> = createSelectors(useServizioStore);
