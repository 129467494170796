import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import { ComponentType } from 'react';
import { SvgProps } from 'react-native-svg';
import Dashboard from '../screens/dashboard';
import { Ionicons, Feather } from '@expo/vector-icons';
import { Avatar } from 'native-base';
import Servizi from '../screens/servizi';
import Chiamate from '../screens/chiamate';
import Rubrica from '../screens/rubrica';


export function useRefreshOnFocus<T>(refetch: () => Promise<T>) {
  const enabledRef = React.useRef(false);

  useFocusEffect(
    React.useCallback(() => {
      if (enabledRef.current) {
        refetch();
      } else {
        enabledRef.current = true;
      }
    }, [refetch])
  );
}


export type TabParamList = {
  Home: undefined;
  Chiamate: undefined;
  Servizi: undefined;
  Rubrica: undefined;
  Profilo: undefined;
};


type TabType = {
  name: keyof TabParamList;
  component: ComponentType<any>;
  label: string;
};

export type TabIconsType = {
  [key in keyof TabParamList]: (props: SvgProps) => JSX.Element;
};


export const routes: TabType[] = [
  {
    name: 'Home',
    component: Dashboard,
    label: 'Home',
  },
  {
    name: 'Chiamate',
    component: Chiamate,
    label: 'Chiamate',
    // badge: 0 
  },
  {
    name: 'Servizi',
    component: Servizi,
    label: 'Servizi',
  },
  {
    name: 'Rubrica',
    component: Rubrica,
    label: 'Rubrica',
  },
];

export const webRoutes: TabType[] = [
  {
    name: 'Home',
    component: Dashboard,
    label: 'Home',
  },
  {
    name: 'Chiamate',
    component: Chiamate,
    label: 'Chiamate',
  },
  {
    name: 'Servizi',
    component: Servizi,
    label: 'Servizi',
  },
  {
    name: 'Rubrica',
    component: Rubrica,
    label: 'Rubrica',
  }
];

export const tabsIcons: TabIconsType = {
  Home: (props: SvgProps) => <Ionicons  name="home" {...props} />,
  Chiamate: (props: SvgProps) => <Ionicons  name="call" {...props} />,
  Servizi: (props: SvgProps) => <Ionicons  name="layers"  {...props} />,
  Rubrica: (props: SvgProps) => <Ionicons  name="book" {...props} />,
  Profilo: (props: SvgProps) => <Ionicons  name="person" {...props} />,
};