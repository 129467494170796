import { useState } from 'react';
import { Input, Icon, Pressable, View } from 'native-base'
import { Ionicons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';

type Props = {
    onChange: (text: string) => void,
    onClear: () => void,
    search: string,
    width: string
}

export default function SearchBar({
    onChange,
    onClear,
    search,
    width
}: Props) {

    const [inputValue, setInputValue] = useState(search);

    const handleInputChange = (text: string) => {
        setInputValue(text);
        onChange(text);
    };

    const handleClear = () => {
        setInputValue('');
        onClear();
    };

    return (
        <Input _dark={{
            bg: "white",
            color: "dark",
            _focus: {
                color: "dark",
                bg: "white"
            }
        }} value={inputValue} onChangeText={handleInputChange} placeholder="Ricerca" variant="filled" width={width} my="2" borderRadius="10" py="3" rounded={"3xl"} mx="auto"  InputLeftElement={<Icon ml="2" size="4" color="gray.800" as={<Ionicons name="search" />} />}
            InputRightElement={search && <TouchableOpacity onPress={handleClear} >
                <Icon mr="2" size="6" color="gray.800" as={<Ionicons name="close-circle" />} />
                </TouchableOpacity>} />
    )
}