import React from "react";
import { HStack, Stack, Heading } from "native-base";
import { Image } from "react-native"
const LogoSegreteria = require('../../../assets/adaptive-icon.png');import { Platform } from "react-native";
type Props = {};

export default function LoginHeader({ }: Props) {
  return (
    <HStack position={
      Platform.OS === "web" ? "absolute" : "relative"
    } alignItems={"center"} justifyContent={{
      base: "center",
      md: "flex-start"

    }} >
      <Image source={LogoSegreteria} alt="Logo Segreteria" resizeMode="contain"
        style={
          Platform.OS === "web" ? {
            width: 120,
            height: 120,
            margin: "auto"
          } : {
            display: "none"
          }
        }
      />
    </HStack>
  );
}
