import React from 'react'
import { Text } from 'native-base'
import Animated, { useAnimatedStyle, interpolate, Extrapolate, SharedValue } from 'react-native-reanimated'

type Props = {
    colorMode: "light" | "dark" | null | undefined,
    activeDotIndex: SharedValue<number>,
    index: number,
    title: string,
    PAGE_WIDTH: number | undefined
}

export default function HeaderItem({title, index, colorMode, activeDotIndex, PAGE_WIDTH}: Props) {

    const animStyle = useAnimatedStyle(() => {
        return {
            opacity: activeDotIndex.value === index ? 1 : 0.5,

            // Crea l'effetto di skew, l'elemento attivo sarà all'inizio della lista
            transform: [
                {
                    translateX: interpolate(
                        activeDotIndex.value,
                        [index - 1, index, index + 1],
                        [PAGE_WIDTH ?? 1 / 2, 0, -(PAGE_WIDTH ?? 1 / 2)], // Modifica per garantire una transizione più fluida
                       'clamp'
                    )
                }
            ],
        }
    })

    return (
        <Animated.View
            key={index}
            style={[{
                alignItems: "flex-start",
            }, animStyle]}
        >
            <Text fontSize={"2xl"} fontWeight={"400"} color={colorMode === 'dark' ? "white" : "dark"} mt="1">
                {title}
            </Text>
        </Animated.View>
    )
}
